import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiLeetcode, SiGitea } from "react-icons/si";
import '../styles/footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  return (
    <footer id="footer" className="footer-section py-8">
      <div className="container mx-auto px-4">
        <div className="footer-content flex flex-col md:flex-row justify-between">
          <div className="contact-info mb-6 md:mb-0">
            <h2 className="text-3xl font-bold mb-4">Me Contacter</h2>
            <p className="mb-2">
              <a href="mailto:contact@remi-lvg.com" className="text-gray-100">📨 contact@remi-lvg.com</a>
            </p>
            <p>📍 Clermont-Ferrand, France</p>
          </div>
          <div className="social-links mb-6 md:mb-0">
            <h2 className="text-3xl font-bold mb-4">Suivez-moi</h2>
            <ul className="space-y-2">
              <li>
                <a href="https://github.com/MrLilian24" target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-100 hover:text-yellow-400">
                  <FaGithub className="text-3xl mr-2" /> GitHub
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/remi-lavergne/" target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-100 hover:text-yellow-400">
                  <FaLinkedin className="text-3xl mr-2" /> LinkedIn
                </a>
              </li>
              <li>
                <a href="https://codefirst.iut.uca.fr/git/remi.lavergne" target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-100 hover:text-yellow-400">
                  <SiGitea className="text-3xl mr-2" /> CodeFirst
                </a>
              </li>
              <li>
                <a href="https://leetcode.com/u/mrlilian24/" target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-100 hover:text-yellow-400">
                  <SiLeetcode className="text-3xl mr-2" /> Leetcode
                </a>
              </li>
            </ul>
          </div>
          <div className="cta-section">
            <h2 className="text-3xl font-bold mb-4">Travaillons ensemble</h2>
            <p>Si vous souhaitez discuter d'un projet ou simplement me rencontrer, n'hésitez pas à envoyer un message.</p>
            <a href="./media/CV.pdf" download className="btn primary-btn mt-4">Télécharger mon CV</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
