import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectCard from './ProjectCard';
import { motion } from 'framer-motion';
import { FaPlus } from 'react-icons/fa';

import { projectsData } from '../projectsData';

const Projects = () => {
  const { t } = useTranslation();
  const [visibleCount, setVisibleCount] = useState(3);
  const [activeCategory, setActiveCategory] = useState('all');

  // Filtrer les projets par catégorie
  const filteredProjects = activeCategory === 'all'
      ? projectsData
      : projectsData.filter(project => project.category === activeCategory);

  // Tri des projets (plus récents en premier)
  const sortedProjects = [...filteredProjects].sort((a, b) => {
    // Extraire l'année de la date
    const getYear = (dateStr) => {
      const match = dateStr.match(/\d{4}/g);
      return match ? parseInt(match[match.length - 1]) : 0;
    };

    const yearA = getYear(a.date);
    const yearB = getYear(b.date);

    // Si les années sont égales, vérifier si le projet est "En cours"
    if (yearA === yearB) {
      if (a.date.includes("En cours")) return -1;
      if (b.date.includes("En cours")) return 1;
      return 0;
    }

    return yearB - yearA; // Tri par année décroissante
  });

  // Observer pour le chargement progressif
  const observerRef = useCallback(node => {
    if (!node) return;
    const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && visibleCount < sortedProjects.length) {
            // Ajouter un délai pour une expérience plus fluide
            setTimeout(() => {
              setVisibleCount(prevCount => Math.min(prevCount + 3, sortedProjects.length));
            }, 300);
          }
        },
        { threshold: 0.1 }
    );
    observer.observe(node);
    return () => observer.disconnect();
  }, [visibleCount, sortedProjects.length]);

  // Reset le nombre de projets visibles lors du changement de catégorie
  useEffect(() => {
    setVisibleCount(3);
  }, [activeCategory]);

  // Animations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }
  };

  // Mapper les catégories pour le ProjectCard
  const getCategoryLabel = (category) => {
    switch(category) {
      case 'university': return t('Universitaire');
      case 'professional': return t('Professionnel');
      case 'personal': return t('Personnel');
      default: return '';
    }
  };

  return (
      <section id="projects" className="projects-section py-16 bg-gray-900 text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <h2 className="section-title text-4xl font-bold text-center md:text-left mb-4 md:mb-0">{t('Projects')}</h2>

            {/* Navigation par catégories simplifiée - alignée à droite sur la même ligne */}
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <button
                  onClick={() => setActiveCategory('all')}
                  className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                      activeCategory === 'all'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                  }`}
              >
                {t('Tous')}
              </button>
              <button
                  onClick={() => setActiveCategory('university')}
                  className={`px-4 py-2 text-sm font-medium ${
                      activeCategory === 'university'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                  }`}
              >
                {t('Universitaires')}
              </button>
              <button
                  onClick={() => setActiveCategory('professional')}
                  className={`px-4 py-2 text-sm font-medium ${
                      activeCategory === 'professional'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                  }`}
              >
                {t('Professionnels')}
              </button>
              <button
                  onClick={() => setActiveCategory('personal')}
                  className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                      activeCategory === 'personal'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                  }`}
              >
                {t('Personnels')}
              </button>
            </div>
          </div>

          {sortedProjects.length === 0 ? (
              <div className="text-center py-12">
                <p className="text-xl text-gray-300">
                  {t('Aucun projet dans cette catégorie.')}
                </p>
              </div>
          ) : (
              <>
                <motion.div
                    className="projects-grid grid gap-8 grid-flow-row md:grid-cols-2 lg:grid-cols-3 auto-rows-auto"
                    style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))' }}
                    key={activeCategory} // Force re-render animation when category changes
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                  {sortedProjects.slice(0, visibleCount).map((project) => (
                      <motion.div
                          key={project.title}
                          variants={itemVariants}
                          className="w-full max-w-md mx-auto"
                      >
                        <ProjectCard
                            project={{
                              ...project,
                              categoryLabel: getCategoryLabel(project.category)
                            }}
                        />
                      </motion.div>
                  ))}
                </motion.div>

                {visibleCount < sortedProjects.length && (
                    <div
                        className="flex justify-center mt-12"
                        ref={observerRef}
                    >
                      <button
                          onClick={() => setVisibleCount(prev => Math.min(prev + 3, sortedProjects.length))}
                          className="bg-transparent hover:bg-blue-600 text-blue-400 hover:text-white border border-blue-400 hover:border-transparent px-6 py-3 rounded-lg transition-colors flex items-center"
                      >
                        <FaPlus className="mr-2" /> {t('Voir plus de projets')}
                      </button>
                    </div>
                )}
              </>
          )}
        </div>
      </section>
  );
};

export default Projects;