import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDesktop, FaServer, FaDatabase, FaTools, FaCodeBranch } from 'react-icons/fa';
import '../styles/skills.css';

const skillsData = {
  frontend: [
    { name: 'HTML', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg' },
    { name: 'CSS', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg' },
    { name: 'JavaScript', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg' },
    { name: 'React', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg' },
  ],
  backend: [
    { name: 'C', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg' },
    { name: 'C++', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg' },
    { name: 'C#', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg' },
    { name: 'Python', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg' },
    { name: 'Bash', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg' },
    { name: 'Lua', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/lua/lua-original.svg' },
    { name: 'Node.js', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg' },
    { name: 'PHP', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg' },
    { name: 'Java', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg' },
  ],
  database: [
    { name: 'PostgreSQL', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg' },
    { name: 'MySQL', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg' },
    { name: 'SQLite', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sqlite/sqlite-original.svg' },
  ],
  tools: [
    { name: 'Docker', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg' },
    { name: 'Git', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg' },
    { name: 'Drone (CI)', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/drone/drone-original.svg' },
    { name: 'SonarQube', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sonarqube/sonarqube-original.svg' },
  ],
  ide: [
    { name: 'Visual Studio Code', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg' },
    { name: 'Visual Studio', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-original.svg' },
    { name: 'CLion', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/clion/clion-original.svg' },
    { name: 'Rider', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rider/rider-original.svg' },
  ]
};

// Configuration des onglets avec icônes
const tabs = [
  { id: 'frontend', label: 'Frontend', icon: <FaDesktop /> },
  { id: 'backend', label: 'Backend', icon: <FaServer /> },
  { id: 'database', label: 'Bases de données', icon: <FaDatabase /> },
  { id: 'tools', label: 'Outils', icon: <FaTools /> },
  { id: 'ide', label: 'IDE/Editeurs', icon: <FaCodeBranch /> }
];

const Skills = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState('frontend');

  return (
      <section id="skills" className="skills-section py-16 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <h2 className="text-4xl font-bold mb-6 md:mb-0">{t('Skills')}</h2>

            {/* Sélecteur mobile */}
            <div className="w-full md:hidden mb-6">
              <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full p-2 rounded bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600"
              >
                {tabs.map(tab => (
                    <option key={tab.id} value={tab.id}>
                      {t(tab.label)}
                    </option>
                ))}
              </select>
            </div>
          </div>

          {/* Onglets desktop */}
          <div className="hidden md:block mb-8">
            <div className="border-b border-gray-300 dark:border-gray-600">
              <div className="flex flex-wrap -mb-px">
                {tabs.map(tab => (
                    <button
                        key={tab.id}
                        onClick={() => setSelectedCategory(tab.id)}
                        className={`inline-flex items-center py-4 px-6 text-sm font-medium text-center border-b-2 ${
                            selectedCategory === tab.id
                                ? 'border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                        }`}
                    >
                      {tab.icon}
                      <span className="ml-2">{t(tab.label)}</span>
                    </button>
                ))}
              </div>
            </div>
          </div>

          {/* Grille de compétences */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))' }}>
            {skillsData[selectedCategory].map((skill, index) => (
                <div
                    key={index}
                    className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-5 flex flex-col items-center text-center transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
                >
                  <div className="w-16 h-16 mb-4 overflow-hidden flex items-center justify-center">
                    <img
                        src={skill.icon}
                        alt={skill.name}
                        className="w-12 h-12 object-contain transition-transform duration-300 hover:scale-110"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `https://via.placeholder.com/80x80?text=${skill.name.charAt(0)}`;
                        }}
                    />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{skill.name}</h3>
                </div>
            ))}
          </div>
        </div>
      </section>
  );
};

export default Skills;