export const projectsData = [
  {
    title: "Let's Go!",
    description: "Application web et mobile pour apprendre l'anglais.",
    fullDescription: "Projet de deuxième année de BUT Informatique. Application web et mobile d'apprentissage interactif de langue. Réalisé en PHP/Blazor ainsi qu'avec Tailwind CSS et JavaScript et langage de balisage Twig (moteur de template) pour la partie web. Réalisé en Kotlin/Andoid pour la partie mobile. Base de données MariaDB.",
    images: ["letsgo.jpg", "pageAccueil.png", "pageAccueil_dark.png", "pageVocabLists_dark.png", "pageExercises_dark.png", "gameFlashcard.png", "gameQCM.png", "pageProfil.png"],
    tags: [
      { name: "PHP", icon: "php" },
      { name: "MariaDB", icon: "mariadb" },
      { name: "Kotlin", icon: "kotlin" },
      { name: "Android", icon: "android" },
      { name: "Blazor", icon: "blazor" },
    ],
    date: "Septembre 2024 - Avril 2025",
    links: [
      { label: "CodeFirst", url: "https://codefirst.iut.uca.fr/git/LetsGo" },
    ],
    category: "university" // universitaire
  },
  {
    title: "Trek 12",
    description: "Trek 12 - Le jeu de plateau en roll & write sur ordinateur !",
    fullDescription: "Trek 12 - Le jeu de plateau en roll & write sur ordinateur ! Application Windows réalisée avec le framework multiplateforme .NET MAUI de Microsoft. Réalisé en C# (code-behind) et langage de balisage XAML (vues).",
    images: ["trek12-1.png", "trek12-2.png", "trek12-3.png", "trek12-4.png"],
    tags: [
      { name: "C#", icon: "csharp" },
      { name: ".NET MAUI", icon: "dotnetcore" }
    ],
    date: "Avril-Juin 2024",
    links: [
      { label: "CodeFirst", url: "https://codefirst.iut.uca.fr/git/remi.lavergne/Trek-12" },
    ],
    category: "university" // universitaire
  },
  {
    title: "Databerry Py.",
    description: "Analyste de données pour l'entreprise Databerry.",
    fullDescription: "Depuis un jeu de données en CSV, nous avons réalisé un script Python pour traiter puis analyser les données et les visualiser. Nous avons utilisé des librairies telles que Matplotlib, Pandas et Numpy pour traiter les données. Nous avons ensuite stocker les données dans une base de données SQL avec Postgres, pour à l'aide de reqûetes SQL, générer des graphiques pour visualiser les données. Pour au final rédiger un rapport pour expliquer les résultats obtenus à notre client.",
    images: ["databerry-1.png", "databerry-2.png", "databerry-3.png"],
    tags: [
      { name: "Python", icon: "python" },
      { name: "PostgreSQL", icon: "postgresql" },
      { name: "Matplotlib", icon: "matplotlib" },
      { name: "Pandas", icon: "pandas" },
      { name: "Numpy", icon: "numpy" }
    ],
    date: "Mai 2024",
    links: [
      { label: "CodeFirst", url: "https://codefirst.iut.uca.fr/git/Blueberry-Py./SAE2.04" },
    ],
    category: "university" // professionnel
  },
  {
    title: "ValoAPI",
    description: "Utilisant l'API officielle de Riot Games, le projet traitera et affichera des données périodiques sur le jeu \"Valorant\".",
    fullDescription: "L'objectif de ce projet est de découvrir le fonctionnement des API et de les utiliser pour récupérer des données. Avec Python et Flask, nous récupérons des données en JSON depuis l'API officielle. Ces données sont traitées puis ensuite stockées dans une base de données MySQL. En plus du stockage des données, nous avons mis en place un serveur web déployé avec Docker, permettant de visualiser après calculs pour le public. Comme par exemple les agents les plus joués, les armes les plus utilisées, etc.",
    images: ["valoapi-1.png", "valoapi-2.png", "valoapi-3.png"],
    tags: [
      { name: "Python", icon: "python" },
      { name: "Flask", icon: "flask" },
      { name: "MySQL", icon: "mysql" },
      { name: "Docker", icon: "docker" },
    ],
    date: "En cours (2024)",
    links: [
      { label: "GitHub", url: "https://github.com/MrLilian24/ValoAPI" },
    ],
    category: "personal" // personnel
  }
];