import React, { useState, useRef } from 'react';
import { FaExternalLinkAlt, FaTimes, FaGithub, FaArrowLeft, FaArrowRight, FaGraduationCap, FaBriefcase, FaLaptopCode } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProjectCard = ({ project }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = (e) => {
    if (e) {
      e.stopPropagation(); // Empêcher la propagation de l'événement
    }
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  // Gestion du click en dehors du modal
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('project-modal-overlay')) {
      closeModal();
    }
  };

  // Gestion des touches clavier (Echap pour fermer)
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition-all focus:outline-none"
            onClick={onClick}
        >
          <FaArrowRight />
        </button>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition-all focus:outline-none"
            onClick={onClick}
        >
          <FaArrowLeft />
        </button>
    );
  };

  const CustomDots = (dots) => {
    return (
        <div className="absolute bottom-3 left-0 right-0">
          <ul className="flex justify-center m-0 p-0"> {dots} </ul>
        </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => CustomDots(dots),
    beforeChange: (current, next) => setCurrentSlide(next),
    customPaging: i => (
        <button className={`w-2 h-2 rounded-full transition-all ${i === currentSlide ? 'bg-white scale-125' : 'bg-gray-400'} mx-1`} />
    ),
    dotsClass: "slick-dots custom-dot-class"
  };

  // Déterminer l'icône et la couleur du badge en fonction de la catégorie
  const getBadgeInfo = () => {
    if (!project.category) return null;

    let icon, bgColor, textColor;

    switch(project.category) {
      case 'university':
        icon = <FaGraduationCap className="mr-1" />;
        bgColor = 'bg-blue-100 dark:bg-blue-900';
        textColor = 'text-blue-800 dark:text-blue-200';
        break;
      case 'professional':
        icon = <FaBriefcase className="mr-1" />;
        bgColor = 'bg-green-100 dark:bg-green-900';
        textColor = 'text-green-800 dark:text-green-200';
        break;
      case 'personal':
        icon = <FaLaptopCode className="mr-1" />;
        bgColor = 'bg-purple-100 dark:bg-purple-900';
        textColor = 'text-purple-800 dark:text-purple-200';
        break;
      default:
        return null;
    }

    return { icon, bgColor, textColor };
  };

  const badgeInfo = getBadgeInfo();

  return (
      <>
        <motion.div
            className="project-card relative bg-white dark:bg-gray-700 bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-4 hover:scale-105 transition-transform flex flex-col justify-between cursor-pointer"
            onClick={openModal}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
        >
          {/* Badge de catégorie intégré à la carte */}
          {badgeInfo && (
              <div className="absolute top-2 left-2 z-10">
            <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${badgeInfo.bgColor} ${badgeInfo.textColor}`}>
              {badgeInfo.icon}
              {project.categoryLabel}
            </span>
              </div>
          )}

          <div>
            <div className="project-image-container mb-2 overflow-hidden rounded-lg">
              <img
                  src={`./img/${project.images[0]}`}
                  alt={project.title}
                  className="w-full h-48 object-cover rounded-lg transition-transform hover:scale-110 duration-300"
                  loading="lazy"
              />
            </div>
            <div className="project-tags flex flex-wrap gap-2 mb-2 justify-center">
              {project.tags.map((tag, idx) => (
                  <span key={idx} className="tag bg-gray-200 dark:bg-gray-600 p-2 rounded-full flex items-center text-xs">
                <img
                    src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${tag.icon}/${tag.icon}-original.svg`}
                    className="w-4 h-4 mr-2"
                    alt={tag.name}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${tag.icon}/${tag.icon}-plain.svg`;
                    }}
                />
                    {tag.name}
              </span>
              ))}
            </div>
            <div className="project-content text-gray-900 dark:text-gray-100">
              <h3 className="project-title text-xl font-bold mb-2">{project.title}</h3>
              <p className="project-description mb-4 line-clamp-3">{project.description}</p>
            </div>
          </div>
          <div>
            <button
                className="btn btn-primary bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center w-full justify-center transition-colors duration-300"
                aria-label={`${t('Voir Plus')} ${project.title}`}
            >
              {t('Voir Plus')} <FaExternalLinkAlt className="ml-2" />
            </button>
          </div>
        </motion.div>

        {/* Modal */}
        <AnimatePresence>
          {isOpen && (
              <div
                  className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75 backdrop-blur-sm project-modal-overlay"
                  onClick={handleOverlayClick}
                  onKeyDown={handleKeyDown}
                  tabIndex={-1}
              >
                <motion.div
                    className="relative w-full max-w-5xl max-h-[90vh] bg-white dark:bg-gray-900 rounded-2xl overflow-hidden shadow-2xl"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ type: 'spring', damping: 20 }}
                    onClick={e => e.stopPropagation()}
                >
                  {/* Close button */}
                  <button
                      onClick={closeModal}
                      className="absolute top-4 right-4 z-50 bg-gray-200 dark:bg-gray-700 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors focus:outline-none"
                      aria-label="Fermer"
                  >
                    <FaTimes className="text-gray-700 dark:text-gray-300" />
                  </button>

                  <div className="grid grid-cols-1 md:grid-cols-2 h-full">
                    {/* Left side - Image slider */}
                    <div className="relative bg-gray-100 dark:bg-gray-800 h-[300px] md:h-full">
                      {/* Badge dans le modal */}
                      {badgeInfo && (
                          <div className="absolute top-4 left-4 z-20">
                      <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${badgeInfo.bgColor} ${badgeInfo.textColor}`}>
                        {badgeInfo.icon}
                        {project.categoryLabel}
                      </span>
                          </div>
                      )}
                      <Slider {...settings} className="h-full" ref={sliderRef}>
                        {project.images.map((image, index) => (
                            <div key={index} className="focus:outline-none h-full">
                              <div className="flex items-center justify-center h-[300px] md:h-[600px] bg-gray-200 dark:bg-gray-800">
                                <img
                                    src={`./img/${image}`}
                                    alt={`${project.title} ${index + 1}`}
                                    className="w-full h-full object-contain"
                                    loading="lazy"
                                />
                              </div>
                            </div>
                        ))}
                      </Slider>
                    </div>

                    {/* Right side - Content */}
                    <div className="p-6 md:p-8 overflow-y-auto max-h-[300px] md:max-h-[600px] scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600">
                      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-900 dark:text-white">{project.title}</h2>

                      <div className="mb-6">
                    <span className="inline-block bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-3 py-1 rounded-full text-sm font-medium">
                      {project.date}
                    </span>
                      </div>

                      <p className="text-gray-700 dark:text-gray-300 mb-6 whitespace-pre-line">{project.fullDescription}</p>

                      {/* All tags */}
                      <div className="mb-6">
                        <h3 className="text-lg font-medium mb-2 text-gray-900 dark:text-white">{t('Technologies')}</h3>
                        <div className="flex flex-wrap gap-2">
                          {project.tags.map((tag, idx) => (
                              <span key={idx} className="bg-gray-100 dark:bg-gray-700 px-3 py-1 rounded-full text-sm font-medium flex items-center">
                          <img
                              src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${tag.icon}/${tag.icon}-original.svg`}
                              className="w-5 h-5 mr-2"
                              alt={tag.name}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${tag.icon}/${tag.icon}-plain.svg`;
                              }}
                          />
                                {tag.name}
                        </span>
                          ))}
                        </div>
                      </div>

                      {/* Links */}
                      {project.links && (
                          <div>
                            <h3 className="text-lg font-medium mb-2 text-gray-900 dark:text-white">{t('Liens')}</h3>
                            <div className="flex flex-wrap gap-3">
                              {project.links.map((link, idx) => (
                                  <a
                                      key={idx}
                                      href={link.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors"
                                      onClick={e => e.stopPropagation()}
                                  >
                                    {link.label.includes('GitHub') ? <FaGithub className="mr-2" /> : <FaExternalLinkAlt className="mr-2" />}
                                    {link.label}
                                  </a>
                              ))}
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </motion.div>
              </div>
          )}
        </AnimatePresence>
      </>
  );
};

export default ProjectCard;